import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import SportImage from "../components/SportImage"
import StarbImage from "../components/StarbImage"

const query = graphql`
  {
    file(relativePath: { eq: "Shoesite.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

function Services() {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query)

  return (
    <Layout>
      <SEO title="Services" />
      <article className="about-page">
        <div className="text-center pt-16">
          <h2 className="pb-4">Exemples de sites</h2>
          <p>
            Les Templates sont à titre d'exemples, le contenu et les images
            appartiennent à leurs propriétaires. Un mixe des templates peut
            également être possible.
          </p>
        </div>
        <svg
          width="100%"
          height="auto"
          class="w-full relative"
          viewBox="0 0 1370 64"
          version="1.1"
          xmlns="https://www.w3.org/2000/svg"
        >
          <g
            id="Page-1"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="Desktop-HD"
              transform="translate(0.000000, -558.000000)"
              fill="#4B5563"
            >
              <path
                d="M0,558 C243.726562,600.97601 472.059896,622.464014 685,622.464014 C897.940104,622.464014 1126.27344,600.97601 1370,558 L1370,622.464014 L0,622.464014 L0,558 Z"
                id="Rectangle-4"
              ></path>
            </g>
          </g>
        </svg>
      </article>
      <section className="text-indigo-200 body-font p-5 bg-gray-600">
        <div className="mx-auto flex px-5  md:flex-row flex-col items-center jobcard">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center">
            <figure className="visible">
              <div>
                <div className="pt-10 px-2 sm:px-6">
                  <span className="inline-block py-1 px-2 rounded-full bg-green-600 text-white  text-xs font-bold tracking-widest mb-2">
                    Site vitrine
                  </span>
                  <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-100">
                    Mettre ses produits
                    <br className="hidden lg:inline-block" />
                    phares en avant
                  </h1>
                  <p className="text-indigo-200 text-base pb-4">
                    Une présentation simple avec une mise en page épurée avec
                    une animation minimaliste
                  </p>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center pb-12">
                      <a
                        href="https://shoes-cedcreate.surge.sh/"
                        class="bg-transparent hover:bg-white-500 text-white-700 font-semibold hover:text-yellow py-2 px-4 border border-white-500 hover:border-transparent rounded"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Voir le site
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </figure>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 sm:block hidden">
            <Image
              fluid={fluid}
              className="object-cover object-center rounded"
            />
          </div>
        </div>
        {/*  */}
        <div className="mx-auto flex px-5  md:flex-row flex-col items-center jobcard">
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 sm:block hidden">
            <SportImage />
          </div>
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-right mb-16 md:mb-0 items-center">
            <figure className="visible">
              <div>
                <div className="pt-10 px-2 sm:px-6">
                  <span className="inline-block py-1 px-2 rounded-full bg-green-600 text-white  text-xs font-bold tracking-widest mb-2">
                    Site vitrine
                  </span>
                  <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-100">
                    Présenter son
                    <br className="hidden lg:inline-block" />
                    Entreprise
                  </h1>
                  <p className="text-indigo-200 text-base pb-4">
                    Beaucoup d'animation qui permet d'avoir un effet visuel lors
                    de la navigation.
                  </p>
                  <div className="flex items-center justify-end">
                    <div className="flex items-center pb-12">
                      <a
                        href="https://cedcreate-sport.surge.sh/"
                        class="bg-transparent hover:bg-white-500 text-white-700 font-semibold hover:text-yellow py-2 px-4 border border-white-500 hover:border-transparent rounded"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Voir le site
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </figure>
          </div>
        </div>
        {/*  */}
        <div className="mx-auto flex px-5  md:flex-row flex-col items-center jobcard">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center">
            <figure className="visible">
              <div>
                <div className="pt-10 px-2 sm:px-6">
                  <span className="inline-block py-1 px-2 rounded-full bg-green-600 text-white  text-xs font-bold tracking-widest mb-2">
                    Site vitrine
                  </span>
                  <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-100">
                    Site Branding
                    <br className="hidden lg:inline-block" />
                    Style
                  </h1>
                  <p className="text-indigo-200 text-base pb-4">
                    Une présentation au même niveau les grandes marques
                    existantes.
                  </p>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center pb-12">
                      <a
                        href="https://cedcreate-silver.surge.sh/"
                        class="bg-transparent hover:bg-white-500 text-white-700 font-semibold hover:text-yellow py-2 px-4 border border-white-500 hover:border-transparent rounded"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Voir le site
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </figure>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 sm:block hidden">
            <StarbImage />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Services
